import React from 'react';
import { graphql } from 'gatsby';

import SEO from 'components/Seo';
import Footer from 'components/Footer';

import LayoutMain from 'components/layout/layoutMain';
import LayoutPanel from 'components/layout/layoutPanel';
import Image from 'components/common/Image';

import AwardsInfo from 'components/cards/AwardsInfo';
import Faq from 'components/common/Faq';
import EmployeeDiscountOfferHeroBanner from 'components/cards/EmployeeDiscountOfferHeroBanner';
import CarbonOffsetInfo from "components/cards/CarbonOffsetInfo";
import FlybuysInfo from 'components/cards/FlybuysInfo';
import Ng40DiscOffer from 'components/cards/Ng40DiscOffer';
import NgSwitchInfo from 'components/cards/NgSwitchInfo';

import faqData from 'constants/components/accordion-list/employee-discount-offer-faq';

import 'styles/templates/employee-discount-offer.css';

export const query = graphql`
	query($slug: String!) {
		employeeDiscountOfferJson(slug: {eq: $slug}) {
			slug,
			company,
			promoCode,
			logo,
		}
	}
`;

const employeeDiscountOfferPage = ({ data }) => {

	const employeeDiscountOffer = data.employeeDiscountOfferJson;

	const faqSettings = {openFirstItem: true};

	// add dynamic accordionList to faqData
	let accordionList = [{
							"title": "I'm already a Kleenheat customer, can I redeem this offer?",
							"content": `<p>As a ${employeeDiscountOffer.company} employee who is an existing Kleenheat customer, we are pleased to offer you <strong>40% off natural gas usage charges for 12 months*</strong>.
							Simply call us on <a href='tel:132180' title='Contact our customer service team'>13 21 80</a> and we’ll increase the discount on your account.</p>`
						}];
	let modifiedAccordionList = accordionList.concat(faqData);

	return (
		<>
			<SEO title={`${employeeDiscountOffer.company} employee offer`}
				 meta={[{name:"robots", content:'noindex'}]} />

        	<LayoutMain>

        		<EmployeeDiscountOfferHeroBanner promoCode={employeeDiscountOffer.promoCode}
					                             company={employeeDiscountOffer.company} />

        		<LayoutPanel theme="mer-theme--light"
	        				 background="mer-bg--ui-light"
		                	 padding="mer-pt-ju mer-pb-sm"
		                	 id="more">
		            <div className="container">
		            	<div className="row mer-panel align-items-center justify-content-center">
		            		<div className="col-10 col-sm-8 col-lg-6 mer-text--size-lg"> 
			                	<div className="content text-center">
			                		{employeeDiscountOffer.logo !== '' && 
			                			<Image className={`mer-panel__image company-logo ${employeeDiscountOffer.slug}`}
		                                 	   src={employeeDiscountOffer.logo}
			                                   alt="Logo" />
			                		}
		                			<h1 className="mt-2 mer-color--primary-cyan">Employee discount offer</h1> 
	                			</div> 
                			</div>
		            	</div>
		            </div>
		        </LayoutPanel>

		        <Ng40DiscOffer panelBackground="mer-bg--ui-light"
            			 	 panelPadding="mer-py-de"
            			 	 company={employeeDiscountOffer.company} />

				<CarbonOffsetInfo  panelBackground="mer-bg--ui-light"
	            		  		   panelPadding="mer-py-de" />

        		<FlybuysInfo panelBackground="mer-bg--ui-light"
            			 	 panelPadding="mer-py-de" />
				
				<AwardsInfo panelBackground="mer-bg--gs-grey-lighter" 
                    panelPadding="mer-py-de" />

  			    <Faq panelPadding="mer-py-lg"
		  			 data={modifiedAccordionList}
					 id="faq"
					 settings={faqSettings} />

    			<NgSwitchInfo theme="mer-theme--cyan"
    						  panelBackground="mer-bg--primary-cyan"
	            			  panelPadding="mer-py-lg"
	            			  promoCode={employeeDiscountOffer.promoCode} />
        	</LayoutMain>
            <Footer />
		</>
		
	);
}

export default employeeDiscountOfferPage;