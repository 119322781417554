import React from 'react';
import Link from 'components/common/Link';
import LayoutPanel from 'components/layout/layoutPanel';
import { Svg } from 'kh-common-components';

import heroBanner from 'images/employee-discount-offer/hero-grin.png';

function EmployeeDiscountOfferHeroBanner (props) {

  return (
    <LayoutPanel background="mer-bg--primary-cyan"                 
                 padding="mer-py-lg"
                 theme="mer-theme--cyan">
          <div className="container">
              <div className="row mer-panel justify-content-around align-items-middle">

                    <div className="col-10 col-sm-7 col-lg-6 mer-text--size-lg">

                        <div className="mer-svg-logo--inline-kleenheat">
                            <Svg title="Kleenheat logo"
                                 icon="kleenheat-logo"
                            /> 
                        </div>

                        <div className="content">
                            <h1>You'll grin over our natural gas discount</h1>
                            <p>We've upped our {props.company} employee discount to <strong>40% off natural gas usage charges for 12 months*</strong> - savings to make you smile.</p>
                            <div className="mer-panel__actions mer-button-group">
                                <Link text="Switch now"
                                link={`/sign-up/promo/${props.promoCode.toUpperCase()}`}
                                linkClass="mer-button mer-button--primary" />

                                <Link text="Existing customer?"
                                link="#faq"
                                linkClass="mer-button mer-button--secondary" />
                            </div>
                        </div>
                    </div>

                    <div className="mer-panel__images col-8 col-sm-5 col-lg-4 col-xl-5">
                        <img src={heroBanner} 
                           className="mer-panel__image product-flourish"
                           alt="Lemon splash" />
                    </div>
              </div>
          </div>
      </LayoutPanel>
  )
}

export default EmployeeDiscountOfferHeroBanner;